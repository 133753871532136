<template>
  <div class="home">
    <Preloader />
    <HomeHero />
    <BornInCanada />
    <AboutUsSection />
    <ProductsAndServices />
    <!-- <Reviews /> -->
    <ContactSection />
    <Carousel />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHero from '../components/HomeHero.vue'
import BornInCanada from '../components/BornInCanada.vue'
import ContactSection from '../components/ContactSection.vue'
import Carousel from '../components/Carousel.vue'
import ProductsAndServices from '../components/ProductsAndServices.vue'
// import Reviews from '../components/Reviews.vue'
import AboutUsSection from '../components/AboutUsSection.vue'
import Preloader from '../components/Preloader.vue'

export default {
  name: 'Home',
  components: {
    HomeHero,
    BornInCanada,
    ContactSection,
    Carousel,
    ProductsAndServices,
    // Reviews,
    AboutUsSection,
    Preloader
}
}
</script>
