<template>
    <div>
        <div class="homeHero">
            <div class="textContainer">
                <h3 class="smallHeadline">
                    Proud to be Canadian
                </h3>
                <h1 class="mainHeadline white">
                    The most advanced screen<br> manufacturer in Canada
                </h1>
                <div class="buttonContainer">
                    <router-link class="routerlink" to="/services">
                        <button class="whiteBtn">
                            View Products
                        </button>
                    </router-link>
                    <a href="#contact" class="routerlink">
                        <button class="whiteBtn">
                            Contact Us
                        </button>
                     </a>   
                </div>
            </div>
            <div class="blackOverlay"></div>
        </div>
    </div>
</template>

<script>
// import ScrollReveal from 'scrollreveal'

    export default {
        name: 'HomeHero',
    }


</script>

<style scoped>

.whiteBtn {
    padding: 15px 40px;
    color: #1d76bb;
    background-color: white;
    border: 1px solid white;
    margin-right: 20px;
    margin-bottom: 20px;
}

.whiteBtn:hover {
    color: white;
    background-color: #1d76bb;
    border: 1px solid #1d76bb;
}


.homeHero {
    height: 800px; 
    width: 100vw;
    background-image: url('../assets/images/3Screens.jpg');
    background-size: cover;
    background-position: 50% 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 9999;
}

.blackOverlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.textContainer {
    padding-left: 25vw;
    position: relative;
    z-index: 55555;
}

/* button {
    position: relative;
    z-index: 55555;
} */

@media screen and (max-width: 1700px) {
        .textContainer {
        padding-left: 15vw;
    }
}

@media screen and (max-width: 1500px) {
        .textContainer {
        padding-left: 10vw;
    }
}

@media screen and (max-width: 1500px) {
        .textContainer {
        padding-left: 5vw;
    }

        .homeHero {
        height: 800px; 
        width: 100%;
    }
}

@media screen and (max-width: 576px){
    .mainHeadline {
        font-size: 30pt;
        line-height: 34pt;
    }

    .homeHero {
    height: 700px; 
    }
}


</style>