<template>
    <div>
        <nav id="top">
            <div class="logoContainer">
                <router-link to="/">
                    <img src="../assets/logos/PangeaScreen_Logo_REV.png" class="logo">
                </router-link>
            </div>
            <div class="navLinksContainer">
                <ul>
                    <router-link to="/about" class="routerlink">
                        <li class="navLink">
                            About Us
                        </li>
                    </router-link>
                    <router-link to="/services" class="routerlink">
                        <button class="productsBtn" @mouseover="openModulesMenu">
                            Products & Services
                        </button>
                        <div class="productsDropdown" id="productsDropdown">
                            <router-link to="/products-services/punch-screen" class="routerlink">
                                <li class="dropdownLink">
                                    Punch Screen
                                </li>
                            </router-link>
                            <router-link to="/products-services/direct-wrap" class="routerlink">
                                <li class="dropdownLink">
                                    Direct Wire Wrap
                                </li>
                            </router-link>
                            <router-link to="/products-services/premium-sintered-mesh" class="routerlink">
                                <li class="dropdownLink">
                                    Premium Sintered Mesh
                                </li>
                            </router-link>
                            <router-link to="/services" class="routerlink">
                                <li class="dropdownLink">
                                    Services
                                </li>
                            </router-link>
                        </div>
                    </router-link>
                    <a href="#contact" class="routerlink">
                        <li class="navLink">
                            Contact
                        </li>
                    </a>
                    <button @click="openMobileMenu" class="noBtn">
                        <i class="fa-solid fa-bars"></i>
                    </button>  
                </ul>
            </div>
            <div class="mobileMenu" id="menu" @click="myFunction">
                 <router-link to="/">
                    <li class="mobileLink">
                        Home
                    </li>
                </router-link>
                 <router-link to="/about">
                    <li class="mobileLink">
                        About Us
                    </li>
                </router-link>
                <router-link to="/services">
                    <li class="mobileLink">
                        Product & Services
                    </li>
                </router-link>
                <a href="#contact">
                    <li class="mobileLink">
                        Contact 
                    </li>
                </a>
            </div>
        </nav>
    </div>
</template>

<script>
import $ from 'jquery'
import jquery from 'jquery'

   $(".navLink").click(function(){
    $("#menu").removeClass("mobile-Menu-Displayed");
    console.log('click 2');
    });


    $("body").click(function(){
    $(".productsDropdown").removeClass("isActive");
    });


 
    export default {
        name: 'NavBar',
        methods: {
            openModulesMenu() {
            document.getElementById("productsDropdown").classList.toggle("isActive");
            },

           openMobileMenu() {
            document.getElementById("menu").classList.toggle("mobile-Menu-Displayed");
            console.log("click");
            },

            myFunction() {
                var element = document.getElementById("menu");
                element.classList.remove("mobile-Menu-Displayed");
                }
            
         },
        

         

    }
</script>

<style lang="scss" scoped>

.logo {
    max-width: 300px;
}

.noBtn {
    border: none; 
    background-color: transparent;
}

.mobileMenu {
    padding: 40px;
    background-color: #1d76bb;
    position: fixed;
    top: 0;
    bottom: 0; 
    left: 0;
    width: 300px;
    transform: translateX(-500px); 
    transition: 0.4s ease all;
}

.mobile-Menu-Displayed {
    transform: translateX(0) !important; 
}

.mobileLink {
    list-style-type: none;
    position: relative;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    font-weight: 13pt;
    cursor: pointer;
    text-decoration: none!important;
}

html {
    scroll-behavior: smooth !important;
}

i {
    display: none;
    color: white; 
    font-size: 16pt;
    cursor: pointer; 
    //background-color: black;
    padding: 20px; 
}

.productsBtn {
    background-color: transparent;
    padding: 0;
    border: none;
    list-style-type: none;
    position: relative;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 20px;
    font-weight: 11pt;
    cursor: pointer;
    text-decoration: none!important;
}

.productsDropdown {
    padding: 20px 20px 40px 20px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
}

.isActive {
    display: block;
}

nav {
    padding: 40px 0; 
    position: absolute;
    top: 0;
    left: 25%;
    width: 50vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99999;
}

ul {
    display: flex;
    flex-direction: row;
}

.navLink {
    list-style-type: none;
    position: relative;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 20px;
    font-weight: 11pt;
    cursor: pointer;
    text-decoration: none!important;
}

.dropdownLink {
    list-style-type: none;
    position: relative;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 20px;
    font-weight: 11pt;
    cursor: pointer;
    padding: 15px 0;
    border-bottom: 1px solid white;
    text-decoration: none!important;
    width: 100%;
}

.navLink:hover, .dropdownLink:hover, .productsBtn:hover {
    color: #1d76bb;
    text-decoration: none !important;
}


@media screen and (max-width: 1700px){  
    nav {
        left: 15%;
        width: 70vw;
    }
}

@media screen and (max-width: 1500px){  
    nav {
        left: 15%;
        width: 80vw;
    }
}

@media screen and (max-width: 1300px){  
    nav {
        left: 40px;
        right: 40px;
        width: 100%;
        padding-right: 40px;
    }
}

@media screen and (max-width: 768px){  
    .navLink, .productsBtn {
        display: none;
    }

        i {
        display: block;
        margin-right: 40px;
    }
}


@media screen and (min-width: 769px){  
    .mobileMenu {
        display: none;
    }
}

</style>