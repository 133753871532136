<template>
  <div>
    <footer>
      <div class="grid-container">
        <div class="grid-item">
          <img src="../assets/logos/PangeaScreen_Logo_REV.png" class="logo" />
          <p class="footer-text">
            Pangea knows and understands the unique requirements of the North American oil and gas industry. We are a Canada-born solution, proudly based in Calgary, and the only vertically integrated screen and flow control systems manufacturing facility on the continent.
          </p>
        </div>
        <div class="grid-item">
          <h3 class="footer-heading">Links</h3>
          <div class="grid-two">
            <div class="links-container">
              <router-link to="/" class="footer-link">
                <p class="footer-link">Home</p>
              </router-link>
              <router-link to="/about" class="footer-link">
                <p class="footer-link">About</p>
              </router-link>
              <router-link to="/services" class="footer-link">
                <p class="footer-link">Products</p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <h3 class="footer-heading-touch">
            Get in <strong>&nbsp;Touch&nbsp;&nbsp;&nbsp;</strong>
          </h3>
          <div class="contact-info">
            <div class="contact-ca">
              <a href="tel:4039690244">
                <p class="footer-phone-number">403-969-0244</p>
              </a>
              <a href="mailto:info@pangeascreen.com?subject=Website Contact">
                <p class="footer-email">info@pangeascreen.com</p>
              </a>
              <a href="https://www.google.com/maps/place/Pangea+Screen+Inc./@51.157657,-114.007459,13z/data=!4m5!3m4!1s0x0:0xbd8f416a4ed0711b!8m2!3d51.1576566!4d-114.0074591?hl=en" target="_blank">
                <address class="footer-address">
                10 Stonehill Pl NE<br />Calgary, AB  T3N 1T7
              </address>
              </a>
            </div>
            <div class="contact-mx">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10009.330564180009!2d-114.0074591!3d51.1576566!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbd8f416a4ed0711b!2sPangea%20Screen%20Inc.!5e0!3m2!1sen!2sca!4v1658339941279!5m2!1sen!2sca" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="social-media-container">
              <!-- <i class="fa-brands fa-instagram"></i>
              <i class="fa-brands fa-facebook-f"></i> -->
              <a href="https://www.linkedin.com/company/pangeascreen/" target="_blank">
                <i class="fa-brands fa-linkedin-in"></i>
              </a>
              <i class="fa-brands fa-youtube"></i>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "NewFooter",
};
</script>

<style lang="scss" scoped>
footer {
  background-color: black;
  padding: 60px 0;
}

.logo {
  max-width: 80%;
}

a:hover {
  text-decoration: none;
  color: #1d76bb;
}

.logo {
    margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1.25fr 0.5fr 1.5fr;
  grid-template-rows: auto;
  gap: 60px;
  width: 50vw;
  margin: auto;
}
.footer-text {
  text-align: left;
  color: grey;
  size: 13pt;
  line-height: 19pt;
}

.footer-heading {
  color: white;
  size: 24pt;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.footer-heading-touch {
  color: white;
  size: 24pt;
  display: flex;
  justify-content: start;
  align-items: start;
}

.links-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.footer-link {
  color: grey;
  text-decoration: none;
  line-height: 15pt;
}

a {
  color: inherit;
  text-decoration: none;
}

.grid-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.contact-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contact-ca {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-mx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-phone-number {
  color: grey;
  size: 13pt;
}

.flag {
  margin-bottom: 10px;
}

.footer-email {
  color: grey;
  size: 10pt;
  margin-bottom: 10px;
}

.footer-address {
  color: grey;
  size: 10pt;
  margin-bottom: 10px;
  text-align: left;
}

.social-media-container i {
  color: white;
  text-align: left;
  font-size: 20pt;
  margin-right: 30px;
}

.social-media-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media screen and (max-width: 1700px) {
  .grid-container {
    width: 70vw;
  }
}

@media screen and (max-width: 1500px) {
  .grid-container {
    width: 80vw;
  }
}

@media screen and (max-width: 1000px) {
  .grid-container {
    grid-template-columns: 1fr;
    width: 90vw;
  }
}

@media screen and (max-width: 768px) {
  .contact-info {
  display: grid;
  grid-template-columns: 1fr;
  }

  i {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1700px) {
  .head-grid-container {
    width: 70vw;
  }
}

@media screen and (max-width: 1500px) {
  .head-grid-container {
    width: 80vw;
  }
}

@media screen and (max-width: 1150px) {
  .grid-container {
    grid-template-columns: 1.5fr 0.5fr;
    gap: 40px;
    width: 90vw;
  }
}

@media screen and (max-width: 1000px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1000px) {
  .head-grid-container {
    grid-template-columns: 1fr;
    width: 90vw;
  }
}
</style>
