<template>
    <div>
        <div class="productsAndServicesContainer">
            <div class="headerContainer">
                <h3 class="smallerHeadline">
                    Products
                </h3>
                <p class="bodyText">
                    Connect with our team of experts for your next project:
                </p>
                <br>
            </div>
            <div class="productsGrid">
                <div class="gridItem">
                    <h2 class="gridItemText">
                        Punch Screen
                    </h2>
                    <img src="../assets/images/1.png">

                    <div class="overlay">
                        <h2 class="gridItemText white">
                            Punch Screen
                        </h2>
                        <div class="bottomPartOverlay">
                            <p class="gridOverlayText white">
                                Punched screen over perforated base pipe offers enhanced tensile, compression and compression to slotted liner.   
                            </p>
                            <router-link class="routerlink" to="/products-services/punch-screen">
                                <button class="mainBtn">
                                    Learn More
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>


                <div class="gridItem">
                    <h2 class="gridItemText">
                        Direct Wire Wrap Premium
                    </h2>   
                    <img src="../assets/images/3.png">
                    <div class="overlay">
                        <h2 class="gridItemText white">
                            Direct Wire Wrap Premium
                        </h2>
                        <div class="bottomPartOverlay">
                            <p class="gridOverlayText white">
                                Direct wire wrap are made of wire tightly wrapped directly on base pipe. DWW offer increased flow area and increased resistance to erosion. 
                            </p>
                            <router-link class="routerlink" to="/products-services/direct-wrap">
                                <button class="mainBtn">
                                    Learn More
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>


                <div class="gridItem">
                    <h2 class="gridItemText">
                        Premium Sintered Mesh
                    </h2>
                    <img src="../assets/images/2.png">
                    <div class="overlay">
                        <h2 class="gridItemText white">
                            Premium Sintered Mesh
                        </h2>
                        <div class="bottomPartOverlay">
                            <p class="gridOverlayText white">
                                With multi layer drainage layers the premium sintered mesh offers optimal drainage area reduced pressure drop  
                            </p>
                            <router-link class="routerlink" to="/products-services/premium-sintered-mesh">
                                <button class="mainBtn">
                                    Learn More
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="servicesSection">
            <h3 class="smallerHeadline">
                Services
            </h3>
            <div class="iconsContainer">
                <div class="iconItem">
                    <img src="../assets/graphics/icon1.png"  alt="Icon" loading="lazy">
                    <h3 class="iconText">
                        Pipe Perforation
                    </h3>
                </div>
                <div class="iconItem">
                    <img src="../assets/graphics/icon2.png"  alt="Icon" loading="lazy">
                    <h3 class="iconText">
                        Spiral Welding
                    </h3>
                <div class="iconItem">
                    <img src="../assets/graphics/icon3.png"  alt="Icon" loading="lazy">
                    <h3 class="iconText">
                        Engineering
                    </h3>
                </div>
            </div>
        </div>
    </div>
     
    </div>
</template>

<script>
    export default {
        name: 'ProductsAndServies'
    }
</script>

<style lang="scss" scoped>

.iconsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    margin-bottom: 80px;
}

.iconItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.iconItem img {
    width: 70px;
}

.iconText {
    font-size: 13pt;
    letter-spacing: 2px;
    font-weight: bold;
}

.servicesSection  {
    width: 50vw;
    margin: auto;
}

.white {
    color: white;
}

.bottomPartOverlay {
    padding: 20px;
}

.overlay {
    position: absolute;
    background-color: rgba($color: #1d76bb, $alpha: 0.95);
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transform: scale(1, 0);
    transition: 0.4s ease all;
}

.gridItem:hover .overlay {
    transform: scale(1, 1);
}
 
.productsAndServicesContainer {
    width: 50vw;
    margin: auto;
    padding: 120px 0 40px 0;
}

.gridItem {
    width: 100%;
    overflow: hidden;
    border: 1px solid #f1f1f1;
    height: 250px;
    position: relative;
    cursor: pointer;
}

.gridItem img {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.gridItemText {
    font-size: 15pt;
    letter-spacing: 2px;
    padding: 40px 10px 0px 20px; 
    font-weight: bold;
}

.productsGrid {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    width: 50vw;
    overflow: hidden;
}

@media screen and (max-width: 1900px){
        .productsGrid {
        gap: 20px;
        width: 70vw;
    }

        .productsAndServicesContainer {
        width: 70vw;
        margin: auto;
        padding: 120px 0 40px 0;
    }

        .servicesSection  {
        width: 70vw;
        margin: auto;
    }
}

@media screen and (max-width: 1700px){
        .productsGrid {
        gap: 20px;
        width: 80vw;
    }

      .productsAndServicesContainer {
        width: 80vw;
        margin: auto;
        padding: 120px 0 40px 0;
    }

      .servicesSection  {
        width: 80vw;
        margin: auto;
    }
}

@media screen and (max-width: 1500px){
        .productsGrid {
        gap: 20px;
        width: 90vw;
    }

      .productsAndServicesContainer {
        width: 90vw;
        margin: auto;
        padding: 120px 0 40px 0;
    }

      .servicesSection  {
        width: 90vw;
        margin: auto;
    }
}

@media screen and (max-width: 1300px){
        .productsGrid {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        width: 90vw;
    }

      .productsAndServicesContainer {
        width: 90vw;
        margin: auto;
        padding: 120px 0 40px 0;
    }

    .gridItem {
        max-width: 100%;
    }

    .gridItem img{
        max-width: 100%;
    }
}

@media screen and (max-width: 1150px){
        .productsGrid {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        width: 90vw;
    }

    .gridItem {
       height: 300px;
    }
}


@media screen and (max-width: 970px){
        .productsGrid {
        grid-template-columns: 1fr;
        gap: 20px;
        width: 90vw;
    }

    .gridItem {
       height: 400px;
    }
}

@media screen and (max-width: 768px){
     .iconsContainer {
    flex-direction: column;
    }
}

@media screen and (max-width: 576px){

    .gridItem {
       height: 300px;
    }
   
}
</style>