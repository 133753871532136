<template>
  <div id="app">
    <div id="nav">
     <!-- <NewNavBar /> -->
     <NavBar />
    </div>
    <vue-page-transition name="fade-in-up">
      <router-view/>
    </vue-page-transition>
    <NewFooter />  
    <!-- <Footer /> -->
    <CookieBanner />
  </div>
</template>


<script>
// import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import CookieBanner from './components/CookieBanner.vue'
import NewFooter from './components/NewFooter.vue'
import NewNavBar from './components/NewNavBar.vue'

    export default {
       components: {
    CookieBanner,
    NewFooter,
    // NewNavBar,
    NavBar,
},
    }
</script>

<style>

</style>
