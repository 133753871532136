<template>
    <div>
        <div class="topSection">
            <div class="gridContainerTop">
                <div class="textContainer">
                    <h1 class="smallerHeadline">
                        A Canada-born Solution
                    </h1>
                    <p class="bodyText">
                        Pangea knows and understands the unique requirements of the North American oil and gas industry. We are a Canada-born solution, proudly based in Calgary, and the only vertically integrated screen and flow control systems manufacturing facility on the continent.
                        <br><br>
                        As part of the Steelhaus Technologies family, Pangea is uniquely positioned to provide products as needed, when needed. With the most advanced quality assurance technologies and processes in the industry, we are driven to position our customers for success as their trusted source of high-quality screens today — and for years to come.
                    </p><br>
                     <a href="https://steelhausinc.com/?utm_source=Pangea_Referral&utm_medium=Referral&utm_campaign=Pangea_Referral&utm_id=Pangea_Referral" target="_blank">
                        <button class="blueBtn">
                            Steelhaus
                        </button>
                    </a>
                    <div class="spacerLine"></div>
                </div>
                 <div class="imgContainer">
                    <img src="../assets/images/born-in-canada.jpg" title="Born In Canada" class="topImg" alt="Born In Canada" loading="lazy">
                </div>
            </div>
        </div>
       <!-- BOTTOM SECTION -->
        <div class="gridContainerBottom">
            <div class="gridItem">
                <div>
                    <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="3000"
                    controls
                    indicators
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                    >
                    <b-carousel-slide
                        img-src="https://i.postimg.cc/NfVpT6zG/vertical1.jpg"  alt="Pangea Products" loading="lazy"
                    ></b-carousel-slide>

                    <b-carousel-slide img-src="https://i.postimg.cc/NMJ1tYhG/vertical2.jpg" alt="Pangea Products" loading="lazy">
                    </b-carousel-slide>

                    <b-carousel-slide img-src="https://i.postimg.cc/x1DLntZm/vertical3.jpg" alt="Pangea Products" loading="lazy"></b-carousel-slide>
                
                    </b-carousel>
                </div>
            </div>
            <div class="gridItem">
                <div class="headlineContainer">
                    <h1 class="smallerHeadline">
                        A vertically integrated approach to screen manufacturing
                    </h1>
                </div>
                <p class="bodyText">
                    Pangea screens are manufactured with quality and safety in mind. We utilize an efficient conveyor belt setup that promotes efficiency and reduces pipe handling. This enables us to reduce quality issues to the pipe and decrease risks to our people.
                    <br><br>
                    Leveraging the abilities of the Steelhaus Technologies network, Pangea's vertically integrated approach has helped customers avoid supply chain disruptions and reduce logistical risks. We manufacturer our own slip-on punch screen with our in-house spiral welders and we machine all components.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { BCarousel } from 'bootstrap-vue'
// Vue.component('b-carousel', BCarousel)

    export default {
        name: 'BornInCanada',
        data() {
            return {
                slide: 0,
                sliding: null
            }
        },
        methods: {
            onSlideStart(slide) {
                this.sliding = true
            },
            onSlideEnd(slide) {
                this.sliding = false
            }
        }
    }
</script>

<style scoped>

#carousel-1 {
    height: 100%;
}

.topSection {
    width: 50vw;
    position: relative;
    margin: auto;
    padding-top: 40px;
}

.spacerLine {
    position: absolute;
    width: 125px;
    height: 2px;
    background-color: #1d76bb;
    right: -150px;
    top: 100px;
    z-index: -1;
    display: none;
}

.topImg {
    width: 100%;
}

.textContainer {
    padding: 80px 0;
    width: 550px;
    position: relative;
}

.img2 {
    max-width: 100%;
}

.gridContainerTop {
    width: 50vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    position: relative;
}

.imgContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.gridContainerBottom {
    width: 50vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    margin: auto;
    padding: 40px 0;
}

.headlineContainer {
    padding-right: 120px;
}

.gridItem {
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 2000px){
        .gridContainerBottom {
        width: 60vw;
    }

        .headlineContainer {
        padding-right: 0;
    }

        .topSection {
        width: 60vw;
    }

        .gridContainerTop {
        width: 60vw;
    }
}

@media screen and (max-width: 1700px){
        .gridContainerBottom {
        width: 70vw;
    }

        .topSection {
        width: 70vw;
    }

        .gridContainerTop {
        width: 70vw;
    }
}

@media screen and (max-width: 1500px){
        .gridContainerBottom {
        width: 80vw;
    }

        .topSection {
        width: 80vw;
    }

        .gridContainerTop {
        width: 80vw;
    }
}

@media screen and (max-width: 1300px){
        .gridContainerBottom {
        width: 90vw;
    }

        .topSection {
        width: 90vw;
    }

        .gridContainerTop {
        width: 90vw;
    }
}

@media screen and (max-width: 1150px){
        .gridContainerBottom {
        grid-template-columns: 1fr;
    }

      .gridContainerTop {
        grid-template-columns: 1fr;
    }

        .textContainer {
        padding: 40px 0;
        width: 90%;
    }

     .spacerLine {
        display: none;
    }
}
</style>