import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/products-services/premium-sintered-mesh',
    name: 'Premium Sintered Mesh',
    component: () => import(/* webpackChunkName: "about" */ '../views/PremiumMesh.vue')
  },
  {
    path: '/products-services/punch-screen',
    name: 'Punched Screen',
    component: () => import(/* webpackChunkName: "about" */ '../views/PunchScreen.vue')
  },
  {
    path: '/products-services/direct-wrap',
    name: 'Direct Wire Wrap',
    component: () => import(/* webpackChunkName: "about" */ '../views/DirectWrap.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "about" */ '../views/Services.vue')
  },
  {
    path: '/products-services/flow-control',
    name: 'Flow Control',
    component: () => import(/* webpackChunkName: "about" */ '../views/FlowControl.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
