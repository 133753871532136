<template>
  <div>
    <div class="preloader-container">
      <img
        src="../assets/logos/PangeaLogoAnimated_Transparent_Resized.gif"
        alt="Pangea"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";

    export default {
        name: 'Preloader',
        mounted() {
            setTimeout(function() {
            $('.preloader-container').addClass('not-displayed');
            }, 2000);
        }
    }
</script>

<style lang="scss" scoped>
.preloader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99999999999999999;
  width: 100vw;
  height: 100vh;
  transition: 1s ease all;
  opacity: 1;
  background-color: rgba($color: white, $alpha: 1.0);
}

.not-displayed {
  opacity: 0;
  background-color: rgba($color: white, $alpha: 0);
  pointer-events: none;

}

.preloader-container img {
  width: 400px;
}
</style>
