<template>
  <div>
    <nav class="newNav">
      <div class="topHalf">
        <div class="topItem">
          <i class="fa-solid fa-phone"></i>
          <p class="">403-969-0244</p>
        </div>
        <div class="topItem">
          <i class="fa-solid fa-envelope"></i>
          <p class="">info@pangeascreen.com</p>
        </div>
      </div>
      <div class="bottomHalf">
        <div class="logoContainer">
          <img src="../assets/logos/pangea-logo.png" class="logo" />
        </div>
        <div class="navLinksContainer">
            
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NewNavBar",
};
</script>

<style lang="scss" scoped>
.newNav {
  width: 100vw;
  margin: auto;
  position: fixed;
  top: 0;
  z-index: 99999;
}

.topHalf {
  width: 100vw; 
  background-color: #1b75b3;
  // border-bottom: 1px solid grey;
  padding: 2px 25vw;
  display: flex;
  flex-direction: row;

}

.topItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-right: 20px;
}
.topItem i {
  color: white;
  font-size: 16pt;
  margin: 0;
}

.topItem p {
  color: white;
  margin-top: 14px;
}
</style>
