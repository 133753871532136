import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VuePageTransition from 'vue-page-transition'
import { BCarousel } from 'bootstrap-vue'
// Set this variable
import mixpanel from 'mixpanel-browser';
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init('4556f91ab1cbd6ce0f1f92db4de3dabd', {debug: true}); 
mixpanel.track('Sign up');


Vue.component('b-carousel', BCarousel)
 
Vue.use(VuePageTransition)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Vue.use(ScrollReveal)
Vue.use(jquery)
