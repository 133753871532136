<template>
    <div>
        <div class="cookieContainer">
            <p class="bodyText white">
                Our website uses cookies. By continuing, you consent to the use of cookies. Read our privacy policy here.
            </p>
            <button class="cookieBtn">
                Agree
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CookieBanner',
           mounted() {
            const cookieContainer = document.querySelector(".cookieContainer")
            const cookieButton = document.querySelector(".cookieBtn")

            cookieButton.addEventListener("click", () => {
                cookieContainer.classList.remove("active");
                localStorage.setItem("cookieBannerDisplayed", "true");
            });

            setTimeout( () => {
                if (!localStorage.getItem("cookieBannerDisplayed"))
                cookieContainer.classList.add("active")
            }, 3000);
        }
    }
</script>

<style scoped>

.cookieBtn {
    color: #1d76bb;
    padding: 10px 40px;
    border-radius: 25px;
    border: 1px solid white;
    background-color: white;
}

.active {
    transform: translateX(0);
}

.cookieContainer {
    width: 500px;
    position: fixed;
    bottom: 0;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 3;
    transform: translateX(-500px);
}

.white {
    color: white;
}

</style>