<template>
    <div>
        <div class="contactSectionContainer" ref="contactSectionContainer" id="contact">
            <div class="contactSectionGrid">
                <div class="contactSectionItem">
                    <h2 class="smallerHeadline white">
                        Contact Us
                    </h2>
                    <p class="bodyText white">
                        Connect with our team of experts for your next project:
                    </p>
                </div>
                <div class="contactSectionItem">
                    <form method="POST"
                     v-on:submit.prevent="sendEmail"
                     >
                        <label for="firstName">
                            First Name
                        </label>
                        <input type="text" v-model="fname"> 
                        <label for="lastName">
                            Last Name
                        </label>
                        <input type="text" v-model="lname"> 
                        <label for="phone">
                            Phone
                        </label>
                        <input type="number" v-model="phone"> 
                         <label for="email">
                            Email
                        </label>
                        <input type="email" v-model="email"> 
                        <button 
                            type="submit"
                            class="formButton"
                            id="submit"
                            >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

    export default {
        name: 'ContactForm',
        data() {
            return {
            fname: "",
            lname: "",
            phone: "",
            email: "",
            };
        },

            async onSubmit() {
            await this.sendEmail().then((res) => {
            this.status = "success"
            this.fname = ""
            this.lname = ""
            this.phone = ""
            this.email = ""
        }).catch((err) => {
            this.status = "error"
        })
        },
        methods:{
            sendEmail() {
            console.log("This would send a email");
    
            var address = "http://192.168.1.8:5014/form/sendHelpEmail";
            
            var parameters = {
                // firstname: this.fname,
                // lastname: this.lname,
                name: `${this.fname} ${this.lname}`,
                email: this.email,
                phone: this.phone,
                text: "TESTING ONLY",
                recipient: [
                    "rdyck@steelhausinc.com",
                    // "struzak@pangeascreen.com",
                ]
            };
    
            axios
                // .post(`${address}/form/sendHelpEmail`, parameters)
                .post("https://www.alpacasys.com/form/customerform", parameters)
                .then((res) => {})
                .catch((error) => console.log(error));
            },

        }
    }
</script>

<style scoped>

.formButton {
    color: #1d76bb;
    padding: 10px 15px;
    border-radius: 25px;
    border: 1px solid white;
    background-color: white;
}

.formButton:hover {
    color: white;
    background-color: transparent;
}

form > label {
    color: white;
}

form {
    padding: 35px;
    border: 1px solid white;
    background : rgba(255, 255, 255, 0.25);
}

label, input {
    margin-bottom: 15px;
}

input {
    width: 100%;
    border-bottom: 1.5px solid white;
    background-color: transparent;
    border-top: none;    
    border-left: none;
    border-right: none;
    outline: none;
    color: white;
}

input:focus {
    border-bottom: 2px solid #1d76bb;
}

.contactSectionContainer {
    width: 100vw; 
    padding: 80px;
    background-image: url('../assets/images/contact2.jpg');
    background-size: cover;
    background-position: 25% 25%;
}
.white {
    color: white;
}

.contactSectionGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    width: 50vw;
    margin: auto;
}

.contactSectionItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}

@media screen and (max-width: 1700px){
        .contactSectionGrid {
        width: 70vw;
    }
}

@media screen and (max-width: 1500px){
        .contactSectionGrid {
        width: 80vw;
    }
}

@media screen and (max-width: 1300px){
        .contactSectionGrid {
        width: 90vw;
        gap: 20px;
    }
}

@media screen and (max-width: 1150px){
        .contactSectionGrid {
        width: 90vw;
        gap: 20px;
        grid-template-columns: 1fr;
    }

    form {
        width: 90%;
    }

     .contactSectionContainer {
        padding: 40px;
       }
}

@media screen and (max-width: 1150px){
      
}

</style>