<template>
    <div>
        <div class="aboutUsContainer">
            <div class="newImg">
                <img src="../assets/images/about-section.jpg" alt="About Us Pangea" loading="lazy">
            </div>
            <div class="text">
                <h3 class="smallerHeadline white">
                    About Us
                </h3>
                <p class="bodyText white">
                    Pangea Screen is a proud Western Canadian company strategically located in Calgary to support our clients in their sand and flow control applications. We are a vertically integrated shop.
                </p>
            </div>

            <!-- <div class="textContainer">
                <h3 class="smallerHeadline white">
                    About Us
                </h3>
                <p class="bodyText white">
                    Pangea screens solutions is a proud Western Canadian company strategically located in Calgary to support our clients in their sand and flow control applications. We are a vertically integrated shop.
                </p>
                <br>
                <router-link to="/about">
                    <button class="mainBtn">
                        Learn More
                    </button>
                </router-link>
            </div>
            <div class="imgCentreContainer">
                <img src="../assets/images/about-img.png" class="centreImg">
            </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutUs'
    }
</script>

<style lang="scss" scoped>
.white {
    color: white;
}

.newImg  {
    height: 700px;
    overflow: hidden;
}

.newImg img {
    width: 100%;
}

.aboutUsContainer {
    position: relative;
    width: 70vw;
    margin: auto;
    margin-top: 40px;
}

.text {
    background-color: #1d76bb;
    padding: 40px;
    position: absolute;
    right: -100px;
    bottom: 0;
    width: 600px;
}


@media screen and (max-width: 2000px){
        .textContainer {
        width: 700px;
        bottom: 0;
        right: 15vw;
    }
}

@media screen and (max-width: 1800px){
        .textContainer {
        width: 600px;
        bottom: 0;
        right: 10vw;
    }

        .newImg  {
        height: 600px;
        overflow: hidden;
    }
}

@media screen and (max-width: 1800px){
        .textContainer {
        width: 500px;
        bottom: 0;
        right: 5vw;
    }
}


@media screen and (max-width: 1300px){
       
    .aboutUsContainer {
        width: 100vw;
     
    }

    .text {
    background-color: #1d76bb;
    padding: 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 600px;
    }
}

@media screen and (max-width: 768px){

    .text {
    background-color: #1d76bb;
    padding: 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    }
}
</style>